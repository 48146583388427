<script>
import {createCssVar} from "../utils/createCssVar";
import {BFormGroup, BFormInput, BFormTag, BFormTags} from "bootstrap-vue";

export default {
  name: "UserProfile",

  components: {
    BFormTags,
    BFormGroup,
    BFormInput,
    BFormTag,
  },

  data() {
    return {
      currencies: ["USD", "PEN", "ARS", "VES", "EUR"],
    };
  },
};
</script>

<template>
  <div :dir="'ltr'">
    <div class="settings-mockup" id="user_admin">
      <div class="settings-mockup-side-bar">
        <div style="border-radius: 0 30px 30px 0" class="settings-mockup-side-bar-item active">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
            <path fill="currentColor"
                  d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m0 7c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1"/>
          </svg>
          Account Settings
        </div>
        <div class="settings-mockup-side-bar-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
            <path fill="currentColor"
                  d="M5 6h18v12H5zm9 3a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3M9 8a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2v-4a2 2 0 0 1-2-2zm-8 2h2v10h16v2H1z"/>
          </svg>
          My balance
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" fill="#94A7B3FF">
            <path
                d="M17.5 16.82L19.94 18.23L19.19 19.53L16 17.69V14H17.5V16.82M24 17C24 20.87 20.87 24 17 24S10 20.87 10 17C10 16.66 10.03 16.33 10.08 16H2V4H20V10.68C22.36 11.81 24 14.21 24 17M10.68 14C10.86 13.64 11.05 13.3 11.28 12.97C11.19 13 11.1 13 11 13C9.34 13 8 11.66 8 10S9.34 7 11 7 14 8.34 14 10C14 10.25 13.96 10.5 13.9 10.73C14.84 10.27 15.89 10 17 10C17.34 10 17.67 10.03 18 10.08V8C16.9 8 16 7.11 16 6H6C6 7.11 5.11 8 4 8V12C5.11 12 6 12.9 6 14H10.68M22 17C22 14.24 19.76 12 17 12S12 14.24 12 17 14.24 22 17 22 22 19.76 22 17Z"/>
          </svg>
          Transaction_history
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#94A7B3FF" width="2em" height="2em" viewBox="0 0 24 24"><title>
            file</title>
            <path d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z"/>
          </svg>
          Identification
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#94A7B3FF" width="2em" height="2em" viewBox="0 0 24 24"><title>
            shield-half-full</title>
            <path
                d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18V21Z"/>
          </svg>
          Responsible gaming
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg fill="#94A7B3FF" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
            key-minus</title>
            <path
                d="M7.5 3C9.5 3 11.1 4.2 11.7 6H21V9H18V12H15V9H11.7C11.1 10.8 9.4 12 7.5 12C5 12 3 10 3 7.5S5 3 7.5 3M7.5 6C6.7 6 6 6.7 6 7.5S6.7 9 7.5 9 9 8.3 9 7.5 8.3 6 7.5 6M8 17H16V19H8V17Z"/>
          </svg>
          Pin extraction
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg fill="#94A7B3FF" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
            forum</title>
            <path
                d="M17,12V3A1,1 0 0,0 16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z"/>
          </svg>
          Messages (0)
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg fill="#94A7B3FF" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
            cash-fast</title>
            <path
                d="M17.12 9.88C16.56 9.32 15.8 9 15 9S13.44 9.32 12.88 9.88C12.32 10.44 12 11.2 12 12S12.32 13.56 12.88 14.12 14.2 15 15 15 16.56 14.68 17.12 14.12 18 12.8 18 12 17.68 10.44 17.12 9.88M7 6V18H23V6H7M21 14C20.47 14 19.96 14.21 19.59 14.59C19.21 14.96 19 15.47 19 16H11C11 15.47 10.79 14.96 10.41 14.59C10.04 14.21 9.53 14 9 14V10C9.53 10 10.04 9.79 10.41 9.41C10.79 9.04 11 8.53 11 8H19C19 8.53 19.21 9.04 19.59 9.41C19.96 9.79 20.47 10 21 10V14M5 8H3C2.45 8 2 7.55 2 7C2 6.45 2.45 6 3 6H5V8M5 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11H5V13M5 18H1C.448 18 0 17.55 0 17C0 16.45 .448 16 1 16H5V18Z"/>
          </svg>
          Deposit
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg fill="#94A7B3FF" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
            bank-transfer</title>
            <path
                d="M15,14V11H18V9L22,12.5L18,16V14H15M14,7.7V9H2V7.7L8,4L14,7.7M7,10H9V15H7V10M3,10H5V15H3V10M13,10V12.5L11,14.3V10H13M9.1,16L8.5,16.5L10.2,18H2V16H9.1M17,15V18H14V20L10,16.5L14,13V15H17Z"/>
          </svg>
          Transfers
        </div>

        <div class="settings-mockup-side-bar-item">
          <svg fill="#94A7B3FF" width="2em" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>
            cash-refund</title>
            <path
                d="M12.3 8.93L9.88 6.5H15.5V10H17V5H9.88L12.3 2.57L11.24 1.5L7 5.75L11.24 10L12.3 8.93M12 14A3 3 0 1 0 15 17A3 3 0 0 0 12 14M3 11V23H21V11M19 19A2 2 0 0 0 17 21H7A2 2 0 0 0 5 19V15A2 2 0 0 0 7 13H17A2 2 0 0 0 19 15Z"/>
          </svg>
          Withdrawals
        </div>

        <div class="settings-mockup-card-header-item">
          <b-button class="w-100 mt-5">Logout</b-button>
        </div>
      </div>

      <div class="settings-mockup-card">
        <div class="settings-mockup-card-header">
          <div class="settings-mockup-card-header-item active">
            <i class="mdi mdi-cog settings-mockup-card-header-item-icon"></i>
            Tab 1
          </div>
          <div class="settings-mockup-card-header-item">
            <i class="mdi mdi-cog settings-mockup-card-header-item-icon"></i>
           Tab 2
          </div>
        </div>
        
        <div class="row mx-0 p-3 settings-mockup-card-body">
          <div class="col py-1 col-md-6">
            <label for="input-small">Firstname</label>
            <b-form-input id="input-small-1"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-2">Email</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-3">Account created</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-5">Birthdate</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-6">Phone</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-74">Country</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-8">Address</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="input-small-9">No. DNI</label>
            <b-form-input id="input-small"></b-form-input>
          </div>

          <div class="col py-1 col-md-6">
            <label for="tags-basic">Currencies</label>
            <b-form-tags separator="," no-add-on-enter :v-model="currencies">
            </b-form-tags>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// --setting-bg-primary
// --setting-bg-secondary
// --setting-border
// --setting-color-primary
// --setting-color-primary-text
// --setting-color-secondary
// --text-color

.settings-mockup {
  display: grid;
  grid-template-columns: 240px 1fr;
  padding: 24px 20px 20px 0 !important;
  gap: 45px;

  .settings-mockup-card-body,
  .settings-mockup-card,
  .settings-mockup-card-header {
    background-color: var(--setting-bg-primary);
  }

  button {
    background-color: var(--setting-color-primary) !important;
    border-radius: 30px;
  }


  &-side-bar {
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    &-item {
      height: 56px;
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 0 16px;
      cursor: pointer;
      text-transform: capitalize;
      color: var(--text-color);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

      &-icon {
        font-size: 20px;
      }

      &.active {
        background-color: var(--setting-color-primary);
        color: var(--setting-color-primary-text);
      }

      &:not(.active):hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  &-card {
    background-color: var(--setting-bg-primary);
    border-radius: 15px;
    padding: 5px 7px;

    &-header {
      display: flex;

      &-item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 8px 16px;
        cursor: pointer;
        color: var(--text-color);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        border-bottom: 2px solid transparent;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
        height: 48px;

        &-icon {
          font-size: 20px;
        }

        &.active {
          color: var(--setting-color-secondary);
          border-color: var(--setting-color-secondary);

          &:hover {
            background-color: color-mix(
                    in srgb,
                    var(--setting-color-secondary) 30%,
                    transparent 100%
            );
          }
        }

        &:not(.active):hover {
          background-color: color-mix(
                  in srgb,
                  var(--setting-color-secondary) 10%,
                  transparent 100%
          );
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 32px 28px 28px 28px;
      color: var(--text-color);
    }
  }
}
</style>
